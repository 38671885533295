import { makeStyles } from "@material-ui/core/styles";
import { colors } from "@utils/constant";

const AddPopupStyle = makeStyles({
	customModal: {
		"& .MuiDialog-paperWidthSm": {
			boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.24)",
			padding: "32px 40px 40px",
			maxWidth: "600px",
			width: "100%",
			"@media (max-width: 991px)": {
				maxWidth: "100%",
				padding: "20px 15px",
				margin: "30px 15px",
				width: "auto",
			},
			"& .MuiDialogTitle-root": {
				padding: "0",
				display: "flex",
				justifyContent: "space-between",
				marginBottom: "30px",
				"@media (max-width: 480px)": {
					marginBottom: "20px",
				},
			},
			"& .MuiDialogContent-root": {
				padding: "0",
				overflow: "hidden",
			},
			"& h2": {
				fontSize: "24px",
				fontWeight: "700",
				lineHeight: "40px",
			},
		},
		"& .close-modal": {
			position: "absolute",
			right: "40px",
			top: "40px",
			cursor: "pointer",
			"@media (max-width: 991px)": {
				top: "28px",
				right: "25px",
			},
		},
		"& .bottom-button-block": {
			padding: "15px 0 0",
			display: "flex",
			"@media (max-width: 480px)": {
				flexDirection: "column",
			},
			"& button": {
				height: "50px",
				lineHeight: "50px",
				fontWeight: "600",
				fontSize: "16px",
				minWidth: "165px",
				textTransform: "uppercase",
				"@media (max-width: 480px)": {
					minWidth: "100%",
					marginBottom: "15px",
				},
				"& + button": {
					marginLeft: "15px",
					"@media (max-width: 480px)": {
						margin: "0 ",
					},
				},
				"&.gray-border-btn": {
					boxShadow: "none",
					border: "1px solid #e7e4f1",
					color: colors.primary,
					textTransform: "uppercase",
					backgroundColor: "transparent",
					"&:hover": {
						boxShadow: "none",
						color: colors.primary,
						backgroundColor: "transparent",
					},
				},
			},
		},
	},
	TableWrapper: {
		"& .MuiBackdrop-root": {
			color: " #fff",
			zIndex: "1201",
		},
		boxShadow: "0 3px 8px 0 rgba(97, 97, 97, 0.08)",
		backgroundColor: colors.white,
		borderRadius: "6px",
		"& .pagination-wrapper": {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "30px",
			overflow: "hidden",
			"@media (max-width: 575px)": {
				flexDirection: "column",
				alignItems: "flex-start",
				padding: "15px",
			},
			"@media (max-width: 374px)": {
				padding: "0",
			},
			"& .total-page p": {
				opacity: "0.7",
				"@media (max-width: 575px)": {
					marginBottom: "15px",
				},
			},
			"& .pagination": {
				display: "flex",
				alignItems: "center",
				"& li": {
					margin: "0 2px",
					"&:last-child": {
						marginLeft: "8px",
					},
					"&:first-child": {
						marginRight: "8px",
					},
					"&:hover a": {
						color: colors.white,
						borderColor: colors.orange,
						backgroundColor: colors.orange,
					},
					"&.no-link": {
						margin: "0 8px",
					},
					"&.disabled a": {
						opacity: "0.3",
						cursor: "default",
						color: colors.black,
						borderColor: "#c6cbd4",
						backgroundColor: "transparent",
					},
					"&.active a": {
						color: colors.white,
						borderColor: colors.orange,
						backgroundColor: colors.orange,
					},
					"& a": {
						border: "solid 1px #c6cbd4",
						fontSize: "14px",
						color: colors.black,
						padding: "0px 10px",
						lineHeight: "1.2",
						borderRadius: "2px",
						height: "32px",
						width: "32px",
						display: "flex",
						textAlign: "center",
						textDecoration: "none !important",
						alignItems: "center",
						justifyContent: "center",
						"& .MuiSvgIcon-root": {
							height: "14px",
							width: "14px",
						},
					},
				},
			},
		},
	},
	customTable: {
		marginTop: 25,
		maxHeight: 680,
		boxShadow: "none",
		"& .MuiTable-root": {
			minWidth: 400,
			tableLayout: "fixed",
			"& .MuiTableCell-root": {
				fontWeight: 500,
				color: colors.black,
				border: "none",
				wordBreak: "break-word",
			},
			"& .MuiTableHead-root": {
				boxShadow: "0 3px 8px 0 rgba(97, 97, 97, 0.08)",
				backgroundColor: colors.white,
				"& .MuiTableCell-root": {
					color: "rgba(33, 33, 33, 0.7)",
					padding: "30px 25px",
					backgroundColor: colors.white,
					borderBottom: "1px solid #f4f4f4",
					"&:first-child": {
						paddingLeft: "30px",
					},
					"&:last-child": {
						paddingRight: "30px",
					},
				},
			},
			"& .MuiTableBody-root": {
				"& .MuiTableCell-root": {
					padding: "22px 25px",
					"&:first-child": {
						paddingLeft: "30px",
					},
					"&:last-child": {
						paddingRight: "30px",
					},
				},
			},
			"& .edit-link": {
				width: "100px",
				"& span": {
					color: colors.orange,
					textTransform: "capitalize",
					cursor: "grab",
				},
			},
			"& .delete-link": {
				width: "110px",
				"& span": {
					color: colors.red,
					textTransform: "capitalize",
					cursor: "grab",
				},
			},
			"& .role-name": {
				width: "60px",
				fontWeight: "bold",
			},
			"& .permission": {
				width: "200px",
			},
			"& .status": {
				width: "150px",
				// color: colors.skyblue,
			},
		},
	},
});

export { AddPopupStyle };
