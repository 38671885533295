import { makeStyles } from "@material-ui/core/styles";

const AddTruckStyle = makeStyles({
  AddTruckWrapper: {
    "@media (max-width: 574px)": {
      flex: "0 0 100%",
      maxWidth: "100%",
    },
  },
});

export { AddTruckStyle };
