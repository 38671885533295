export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_SUCCESS = "SET_PROFILE_SUCCESS";
export const SET_PROFILE_FAILURE = "SET_PROFILE_FAILURE";

export const SET_SELECTED_TRUCK_ID = "SET_SELECTED_TRUCK_ID";
export const SET_SELECTED_TRUCK_ID_SUCCESS = "SET_SELECTED_TRUCK_ID_SUCCESS";
export const SET_SELECTED_TRUCK_ID_FAILURE = "SET_SELECTED_TRUCK_ID_FAILURE";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS";
export const SET_NOTIFICATION_FAILURE = "SET_NOTIFICATION_FAILURE";

export const GET_INVOICING_PERIOD = "GET_INVOICING_PERIOD";
export const GET_INVOICING_PERIOD_SUCCESS = "GET_INVOICING_PERIOD_SUCCESS";
export const GET_INVOICING_PERIOD_FAILURE = "GET_INVOICING_PERIOD_FAILURE";

export const GET_JOBTYPES = "GET_JOBTYPES";
export const GET_JOBTYPES_SUCCESS = "GET_JOBTYPES_SUCCESS";
export const GET_JOBTYPES_FAILURE = "GET_JOBTYPES_FAILURE";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_ADDITIONAL_CHARGE_TYPE = "GET_ADDITIONAL_CHARGE_TYPE";
export const GET_ADDITIONAL_CHARGE_TYPE_SUCCESS =
  "GET_ADDITIONAL_CHARGE_TYPE_SUCCESS";
export const GET_ADDITIONAL_CHARGE_TYPE_FAILURE =
  "GET_ADDITIONAL_CHARGE_TYPE_FAILURE";

export const GET_DRIVER_TYPE = "GET_DRIVER_TYPE";
export const GET_DRIVER_TYPE_SUCCESS = "GET_DRIVER_TYPE_SUCCESS";
export const GET_DRIVER_TYPE_FAILURE = "GET_DRIVER_TYPE_FAILURE";

export const GET_ASIC_TYPE = "GET_ASIC_TYPE";
export const GET_ASIC_TYPE_SUCCESS = "GET_ASIC_TYPE_SUCCESS";
export const GET_ASIC_TYPE_FAILURE = "GET_ASIC_TYPE_FAILURE";

export const GET_CARGO_TYPE = "GET_CARGO_TYPE";
export const GET_CARGO_TYPE_SUCCESS = "GET_CARGO_TYPE_SUCCESS";
export const GET_CARGO_TYPE_FAILURE = "GET_CARGO_TYPE_FAILURE";

export const GET_CTOS = "GET_CTOS";
export const GET_CTOS_SUCCESS = "GET_CTOS_SUCCESS";
export const GET_CTOS_FAILURE = "GET_CTOS_FAILURE";

export const GET_REQUEST_STATUS = "GET_REQUEST_STATUS";
export const GET_REQUEST_STATUS_SUCCESS = "GET_REQUEST_STATUS_SUCCESS";
export const GET_REQUEST_STATUS_FAILURE = "GET_REQUEST_STATUS_FAILURE";

export const GET_DRIVER_LICENSE_TYPE = "GET_DRIVER_LICENSE_TYPE";
export const GET_DRIVER_LICENSE_TYPE_SUCCESS =
  "GET_DRIVER_LICENSE_TYPE_SUCCESS";
export const GET_DRIVER_LICENSE_TYPE_FAILURE =
  "GET_DRIVER_LICENSE_TYPE_FAILURE";

export const GET_PRESIGNEDURL_SUCCESS = "GET_PRESIGNEDURL_SUCCESS";
export const GET_PRESIGNEDURL_FAILURE = "GET_PRESIGNEDURL_FAILURE";

export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILURE = "IMAGE_UPLOAD_FAILURE";

export const GET_TRUCK_TYPE = "GET_TRUCK_TYPE";
export const GET_TRUCK_TYPE_SUCCESS = "GET_TRUCK_TYPE_SUCCESS";
export const GET_TRUCK_TYPE_FAILURE = "GET_TRUCK_TYPE_FAILURE";

export const GET_JOB_STATUS = "GET_JOB_STATUS";
export const GET_JOB_STATUS_SUCCESS = "GET_JOB_STATUS_SUCCESS";
export const GET_JOB_STATUS_FAILURE = "GET_JOB_STATUS_FAILURE";

export const GET_CUSTOMER_CATEGORIES = "GET_CUSTOMER_CATEGORIES";
export const GET_CUSTOMER_CATEGORIES_SUCCESS =
  "GET_CUSTOMER_CATEGORIES_SUCCESS";
export const GET_CUSTOMER_CATEGORIES_FAILURE =
  "GET_CUSTOMER_CATEGORIES_FAILURE";

export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";

export const GET_ROLES_PERMISSIONS = "GET_ROLES_PERMISSIONS";
export const GET_ROLES_PERMISSIONS_SUCCESS = "GET_ROLES_PERMISSIONS_SUCCESS";
export const GET_ROLES_PERMISSIONS_FAILURE = "GET_ROLES_PERMISSIONS_FAILURE";

export const SET_ROLES_PERMISSIONS = "SET_ROLES_PERMISSIONS";
export const SET_ROLES_PERMISSIONS_SUCCESS = "SET_ROLES_PERMISSIONS_SUCCESS";
export const SET_ROLES_PERMISSIONS_FAILURE = "SET_ROLES_PERMISSIONS_FAILURE";

export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";

export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";

export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAILURE = "EDIT_ROLE_FAILURE";

export const PROFILE_VERIFY_EMAIL = "PROFILE_VERIFY_EMAIL";
export const PROFILE_VERIFY_EMAIL_SUCCESS = "PROFILE_VERIFY_EMAIL_SUCCESS";
export const PROFILE_VERIFY_EMAIL_FAILURE = "PROFILE_VERIFY_EMAIL_FAILURE";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const FETCH_PARENT_CUSTOMERS = "FETCH_PARENT_CUSTOMERS";
export const FETCH_PARENT_CUSTOMERS_SUCCESS = "FETCH_PARENT_CUSTOMERS_SUCCESS";
export const FETCH_PARENT_CUSTOMERS_FAILURE = "FETCH_PARENT_CUSTOMERS_FAILURE";

export const FETCH_CUSTOMER_ID = "FETCH_CUSTOMER_ID";
export const FETCH_CUSTOMER_ID_SUCCESS = "FETCH_CUSTOMER_ID_SUCCESS";
export const FETCH_CUSTOMER_ID_FAILURE = "FETCH_CUSTOMER_ID_FAILURE";

export const FETCH_CHILD_CUSTOMER = "FETCH_CHILD_CUSTOMER";
export const FETCH_CHILD_CUSTOMER_SUCCESS = "FETCH_CHILD_CUSTOMER_SUCCESS";
export const FETCH_CHILD_CUSTOMER_FAILURE = "FETCH_CHILD_CUSTOMER_FAILURE";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";

export const FETCH_CUSTOMER_JOBS = "FETCH_CUSTOMER_JOBS";
export const FETCH_CUSTOMER_JOBS_SUCCESS = "FETCH_CUSTOMER_JOBS_SUCCESS";
export const FETCH_CUSTOMER_JOBS_FAILURE = "FETCH_CUSTOMER_JOBS_FAILURE";

export const FETCH_CUSTOMER_INVOICES = "FETCH_CUSTOMER_INVOICES";
export const FETCH_CUSTOMER_INVOICES_SUCCESS =
  "FETCH_CUSTOMER_INVOICES_SUCCESS";
export const FETCH_CUSTOMER_INVOICES_FAILURE =
  "FETCH_CUSTOMER_INVOICES_FAILURE";

export const FETCH_CUSTOMER_AWB = "FETCH_CUSTOMER_AWB";
export const FETCH_CUSTOMER_AWB_SUCCESS = "FETCH_CUSTOMER_AWB_SUCCESS";
export const FETCH_CUSTOMER_AWB_FAILURE = "FETCH_CUSTOMER_AWB_FAILURE";

export const DELETE_CUSTOMER_AWB = "DELETE_CUSTOMER_AWB";
export const DELETE_CUSTOMER_AWB_SUCCESS = "DELETE_CUSTOMER_AWB_SUCCESS";
export const DELETE_CUSTOMER_AWB_FAILURE = "DELETE_CUSTOMER_AWB_FAILURE";

export const IMPORT_CUSTOMER_CSV = "IMPORT_CUSTOMER_CSV";
export const IMPORT_CUSTOMER_CSV_SUCCESS = "IMPORT_CUSTOMER_CSV_SUCCESS";
export const IMPORT_CUSTOMER_CSV_FAILURE = "IMPORT_CUSTOMER_CSV_FAILURE";

export const IMPORT_CUSTOMER_AWB = "IMPORT_CUSTOMER_AWB";
export const IMPORT_CUSTOMER_AWB_SUCCESS = "IMPORT_CUSTOMER_AWB_SUCCESS";
export const IMPORT_CUSTOMER_AWB_FAILURE = "IMPORT_CUSTOMER_AWB_FAILURE";

export const FETCH_CUSTOMER_CONSIGNMENT = "FETCH_CUSTOMER_CONSIGNMENT";
export const FETCH_CUSTOMER_CONSIGNMENT_SUCCESS =
  "FETCH_CUSTOMER_CONSIGNMENT_SUCCESS";
export const FETCH_CUSTOMER_CONSIGNMENT_FAILURE =
  "FETCH_CUSTOMER_CONSIGNMENT_FAILURE";

export const DELETE_CUSTOMER_CONSIGNMENT = "DELETE_CUSTOMER_CONSIGNMENT";
export const DELETE_CUSTOMER_CONSIGNMENT_SUCCESS =
  "DELETE_CUSTOMER_CONSIGNMENT_SUCCESS";
export const DELETE_CUSTOMER_CONSIGNMENT_FAILURE =
  "DELETE_CUSTOMER_CONSIGNMENT_FAILURE";

export const IMPORT_CUSTOMER_CONSIGNMENT = "IMPORT_CUSTOMER_CONSIGNMENT";
export const IMPORT_CUSTOMER_CONSIGNMENT_SUCCESS =
  "IMPORT_CUSTOMER_CONSIGNMENT_SUCCESS";
export const IMPORT_CUSTOMER_CONSIGNMENT_FAILURE =
  "IMPORT_CUSTOMER_CONSIGNMENT_FAILURE";

export const FETCH_CUSTOMER_CURRENT_ACTIVITES =
  "FETCH_CUSTOMER_CURRENT_ACTIVITES";
export const FETCH_CUSTOMER_CURRENT_ACTIVITES_SUCCESS =
  "FETCH_CUSTOMER_CURRENT_ACTIVITES_SUCCESS";
export const FETCH_CUSTOMER_CURRENT_ACTIVITES_FAILURE =
  "FETCH_CUSTOMER_CURRENT_ACTIVITES_FAILURE";

export const GENERATE_INVOICE = "GENERATE_INVOICE";
export const GENERATE_INVOICE_SUCCESS = "GENERATE_INVOICE_SUCCESS";
export const GENERATE_INVOICE_FAILURE = "GENERATE_INVOICE_FAILURE";

export const PREVIEW_INVOICE = "PREVIEW_INVOICE";
export const PREVIEW_INVOICE_SUCCESS = "PREVIEW_INVOICE_SUCCESS";
export const PREVIEW_INVOICE_FAILURE = "PREVIEW_INVOICE_FAILURE";

export const SEND_INVOICE_FOR_APPROVAL = "SEND_INVOICE_FOR_APPROVAL";
export const SEND_INVOICE_FOR_APPROVAL_SUCCESS =
  "SEND_INVOICE_FOR_APPROVAL_SUCCESS";
export const SEND_INVOICE_FOR_APPROVAL_FAILURE =
  "SEND_INVOICE_FOR_APPROVAL_FAILURE";

export const FETCH_CUSTOMER_USERS = "FETCH_CUSTOMER_USERS";
export const FETCH_CUSTOMER_USERS_SUCCESS = "FETCH_CUSTOMER_USERS_SUCCESS";
export const FETCH_CUSTOMER_USERS_FAILURE = "FETCH_CUSTOMER_USERS_FAILURE";

export const ADD_CUSTOMER_USERS = "ADD_CUSTOMER_USERS";
export const ADD_CUSTOMER_USERS_SUCCESS = "ADD_CUSTOMER_USERS_SUCCESS";
export const ADD_CUSTOMER_USERS_FAILURE = "ADD_USERS_FAILURE";

export const EDIT_CUSTOMER_USERS = "EDIT_CUSTOMER_USERS";
export const EDIT_CUSTOMER_USERS_SUCCESS = "EDIT_CUSTOMER_USERS_SUCCESS";
export const EDIT_CUSTOMER_USERS_FAILURE = "EDIT_CUSTOMER_USERS_FAILURE";

export const DELETE_CUSTOMER_USERS = "DELETE_CUSTOMER_USERS";
export const DELETE_CUSTOMER_USERS_SUCCESS = "DELETE_CUSTOMER_USERS_SUCCESS";
export const DELETE_CUSTOMER_USERS_FAILURE = "DELETE_CUSTOMER_USERS_FAILURE";

export const FETCH_CUSTOMER_ADDRESS = "FETCH_CUSTOMER_ADDRESS";
export const FETCH_CUSTOMER_ADDRESS_SUCCESS = "FETCH_CUSTOMER_ADDRESS_SUCCESS";
export const FETCH_CUSTOMER_ADDRESS_FAILURE = "FETCH_CUSTOMER_ADDRESS_FAILURE";

export const ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS";
export const ADD_CUSTOMER_ADDRESS_SUCCESS = "ADD_CUSTOMER_ADDRESS_SUCCESS";
export const ADD_CUSTOMER_ADDRESS_FAILURE = "ADD_CUSTOMER_ADDRESS_FAILURE";

export const EDIT_CUSTOMER_ADDRESS = "EDIT_CUSTOMER_ADDRESS";
export const EDIT_CUSTOMER_ADDRESS_SUCCESS = "EDIT_CUSTOMER_ADDRESS_SUCCESS";
export const EDIT_CUSTOMER_ADDRESS_FAILURE = "EDIT_CUSTOMER_ADDRESS_FAILURE";

export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS";
export const DELETE_CUSTOMER_ADDRESS_SUCCESS =
  "DELETE_CUSTOMER_ADDRESS_SUCCESS";
export const DELETE_CUSTOMER_ADDRESS_FAILURE =
  "DELETE_CUSTOMER_ADDRESS_FAILURE";

export const FETCH_CUSTOMER_CREDIT_NOTE = "FETCH_CUSTOMER_CREDIT_NOTE";
export const FETCH_CUSTOMER_CREDIT_NOTE_SUCCESS =
  "FETCH_CUSTOMER_CREDIT_NOTE_SUCCESS";
export const FETCH_CUSTOMER_CREDIT_NOTE_FAILURE =
  "FETCH_CUSTOMER_CREDIT_NOTE_FAILURE";

export const ADD_CUSTOMER_CREDIT_NOTE = "ADD_CUSTOMER_CREDIT_NOTE";
export const ADD_CUSTOMER_CREDIT_NOTE_SUCCESS =
  "ADD_CUSTOMER_CREDIT_NOTE_SUCCESS";
export const ADD_CUSTOMER_CREDIT_NOTE_FAILURE =
  "ADD_CUSTOMER_CREDIT_NOTE_FAILURE";

export const EDIT_CUSTOMER_CREDIT_NOTE = "EDIT_CUSTOMER_CREDIT_NOTE";
export const EDIT_CUSTOMER_CREDIT_NOTE_SUCCESS =
  "EDIT_CUSTOMER_CREDIT_NOTE_SUCCESS";
export const EDIT_CUSTOMER_CREDIT_NOTE_FAILURE =
  "EDIT_CUSTOMER_CREDIT_NOTE_FAILURE";

export const DELETE_CUSTOMER_CREDIT_NOTE = "DELETE_CUSTOMER_CREDIT_NOTE";
export const DELETE_CUSTOMER_CREDIT_NOTE_SUCCESS =
  "DELETE_CUSTOMER_CREDIT_NOTE_SUCCESS";
export const DELETE_CUSTOMER_CREDIT_NOTE_FAILURE =
  "DELETE_CUSTOMER_CREDIT_NOTE_FAILURE";

export const SEND_CREDIT_NOTE = "SEND_CREDIT_NOTE";
export const SEND_CREDIT_NOTE_SUCCESS = "SEND_CREDIT_NOTE_SUCCESS";
export const SEND_CREDIT_NOTE_FAILURE = "SEND_CREDIT_NOTE_FAILURE";

export const GET_SFTP = "GET_SFTP";
export const GET_SFTP_SUCCESS = "GET_SFTP_SUCCESS";
export const GET_SFTP_FAILURE = "GET_SFTP_FAILURE";

export const SET_SFTP = "SET_SFTP";
export const SET_SFTP_SUCCESS = "SET_SFTP_SUCCESS";
export const SET_SFTP_FAILURE = "SET_SFTP_FAILURE";

export const FETCH_PER_JOB = "FETCH_PER_JOB";
export const FETCH_PER_JOB_SUCCESS = "FETCH_PER_JOB_SUCCESS";
export const FETCH_PER_JOB_FAILURE = "FETCH_PER_JOB_FAILURE";

export const UPDATE_PER_JOB = "UPDATE_PER_JOB";
export const UPDATE_PER_JOB_SUCCESS = "UPDATE_PER_JOB_SUCCESS";
export const UPDATE_PER_JOB_FAILURE = "UPDATE_PER_JOB_FAILURE";

export const UPDATE_ULD_PER_JOB = "UPDATE_ULD_PER_JOB";
export const UPDATE_ULD_PER_JOB_SUCCESS = "UPDATE_ULD_PER_JOB_SUCCESS";
export const UPDATE_ULD_PER_JOB_FAILURE = "UPDATE_ULD_PER_JOB_FAILURE";

export const ISENABLE_PER_JOB = "ISENABLE_PER_JOB";
export const ISENABLE_PER_JOB_SUCCESS = "ISENABLE_PER_JOB_SUCCESS";
export const ISENABLE_PER_JOB_FAILURE = "ISENABLE_PER_JOB_FAILURE";

export const ISENABLE_ULD_PER_JOB = "ISENABLE_ULD_PER_JOB";
export const ISENABLE_ULD_PER_JOB_SUCCESS = "ISENABLE_ULD_PER_JOB_SUCCESS";
export const ISENABLE_ULD_PER_JOB_FAILURE = "ISENABLE_ULD_PER_JOB_FAILURE";

export const FETCH_PRICE_MATRIX = "FETCH_PRICE_MATRIX";
export const FETCH_PRICE_MATRIX_SUCCESS = "FETCH_PRICE_MATRIX_SUCCESS";
export const FETCH_PRICE_MATRIX_FAILURE = "FETCH_PRICE_MATRIX_FAILURE";

export const FETCH_PRICE_MATRIX_CONSIGNMENT = "FETCH_PRICE_MATRIX_CONSIGNMENT";
export const FETCH_PRICE_MATRIX_CONSIGNMENT_SUCCESS =
  "FETCH_PRICE_MATRIX_CONSIGNMENT_SUCCESS";
export const FETCH_PRICE_MATRIX_CONSIGNMENT_FAILURE =
  "FETCH_PRICE_MATRIX_CONSIGNMENT_FAILURE";

export const UPDATE_PRICE_MATRIX_CONSIGNMENT =
  "UPDATE_PRICE_MATRIX_CONSIGNMENT";
export const UPDATE_PRICE_MATRIX_CONSIGNMENT_SUCCESS =
  "UPDATE_PRICE_MATRIX_CONSIGNMENT_SUCCESS";
export const UPDATE_PRICE_MATRIX_CONSIGNMENT_FAILURE =
  "UPDATE_PRICE_MATRIX_CONSIGNMENT_FAILURE";

export const UPDATE_PRICE_MATRIX_SUCCESS = "UPDATE_PRICE_MATRIX_SUCCESS";
export const UPDATE_PRICE_MATRIX_FAILURE = "UPDATE_PRICE_MATRIX_FAILURE";

export const UPDATE_FUEL_SURCHARGE = "UPDATE_FUEL_SURCHARGE";
export const UPDATE_FUEL_SURCHARGE_SUCCESS = "UPDATE_FUEL_SURCHARGE_SUCCESS";
export const UPDATE_FUEL_SURCHARGE_FAILURE = "UPDATE_FUEL_SURCHARGE_FAILURE";

export const UPDATE_PRELOAD_CHARGE = "UPDATE_PRELOAD_CHARGE";
export const UPDATE_PRELOAD_CHARGE_SUCCESS = "UPDATE_PRELOAD_CHARGE_SUCCESS";
export const UPDATE_PRELOAD_CHARGE_FAILURE = "UPDATE_PRELOAD_CHARGE_FAILURE";

export const UPDATE_MINIMUM_INVOICE_VALUE = "UPDATE_MINIMUM_INVOICE_VALUE";
export const UPDATE_MINIMUM_INVOICE_VALUE_SUCCESS =
  "UPDATE_MINIMUM_INVOICE_VALUE_SUCCESS";
export const UPDATE_MINIMUM_INVOICE_VALUE_FAILURE =
  "UPDATE_MINIMUM_INVOICE_VALUE_FAILURE";

export const GET_MINIMUM_DAILY_HOURS = "GET_MINIMUM_DAILY_HOURS";
export const GET_MINIMUM_DAILY_HOURS_SUCCESS =
  "GET_MINIMUM_DAILY_HOURS_SUCCESS";
export const GET_MINIMUM_DAILY_HOURS_FAILURE =
  "GET_MINIMUM_DAILY_HOURS_FAILURE";

export const UPDATE_VALIDITY = "UPDATE_VALIDITY";
export const UPDATE_VALIDITY_SUCCESS = "UPDATE_VALIDITY_SUCCESS";
export const UPDATE_VALIDITY_FAILURE = "UPDATE_VALIDITY_FAILURE";

export const GET_DAILY_MINIMUM_HOURS = "GET_DAILY_MINIMUM_HOURS";
export const GET_DAILY_MINIMUM_HOURS_SUCCESS =
  "GET_DAILY_MINIMUM_HOURS_SUCCESS";
export const GET_DAILY_MINIMUM_HOURS_FAILURE =
  "GET_DAILY_MINIMUM_HOURS_FAILURE";

export const GET_DAILY_MINIMUM_HOURS_CONSIGNMENT =
  "GET_DAILY_MINIMUM_HOURS_CONSIGNMENT";
export const GET_DAILY_MINIMUM_HOURS_CONSIGNMENT_SUCCESS =
  "GET_DAILY_MINIMUM_HOURS_CONSIGNMENT_SUCCESS";
export const GET_DAILY_MINIMUM_HOURS_CONSIGNMENT_FAILURE =
  "GET_DAILY_MINIMUM_HOURS_CONSIGNMENT_FAILURE";

export const UPDATE_DAILY_MINIMUM_HOURS = "UPDATE_DAILY_MINIMUM_HOURS";
export const UPDATE_DAILY_MINIMUM_HOURS_SUCCESS =
  "UPDATE_DAILY_MINIMUM_HOURS_SUCCESS";
export const UPDATE_DAILY_MINIMUM_HOURS_FAILURE =
  "UPDATE_DAILY_MINIMUM_HOURS_FAILURE";

export const UPDATE_DAILY_MINIMUM_HOURS_CONSIGNMENT =
  "UPDATE_DAILY_MINIMUM_HOURS_CONSIGNMENT";
export const UPDATE_DAILY_MINIMUM_HOURS_CONSIGNMENT_SUCCESS =
  "UPDATE_DAILY_MINIMUM_HOURS_CONSIGNMENT_SUCCESS";
export const UPDATE_DAILY_MINIMUM_HOURS_CONSIGNMENT_FAILURE =
  "UPDATE_DAILY_MINIMUM_HOURS_CONSIGNMENT_FAILURE";

export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAILURE = "FETCH_DRIVERS_FAILURE";

export const ADD_DRIVER = "ADD_DRIVER";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_FAILURE = "ADD_DRIVER_FAILURE";

export const IMPORT_DRIVERS_CSV = "IMPORT_DRIVERS_CSV";
export const IMPORT_DRIVERS_CSV_SUCCESS = "IMPORT_DRIVERS_CSV_SUCCESS";
export const IMPORT_DRIVERS_CSV_FAILURE = "IMPORT_DRIVERS_CSV_FAILURE";

export const FETCH_DRIVERS_ID = "FETCH_DRIVERS_ID";
export const FETCH_DRIVERS_ID_SUCCESS = "FETCH_DRIVERS_ID_SUCCESS";
export const FETCH_DRIVERS_ID_FAILURE = "FETCH_DRIVERS_ID_FAILURE";

export const EDIT_DRIVER = "EDIT_DRIVER";
export const EDIT_DRIVER_SUCCESS = "EDIT_DRIVER_SUCCESS";
export const EDIT_DRIVER_FAILURE = "EDIT_DRIVER_FAILURE";

export const ASSIGN_DRIVER = "ASSIGN_DRIVER";
export const ASSIGN_DRIVER_SUCCESS = "ASSIGN_DRIVER_SUCCESS";
export const ASSIGN_DRIVER_FAILURE = "ASSIGN_DRIVER_FAILURE";

export const RE_ASSIGN_DRIVER = "RE_ASSIGN_DRIVER";
export const RE_ASSIGN_DRIVER_SUCCESS = "RE_ASSIGN_DRIVER_SUCCESS";
export const RE_ASSIGN_DRIVER_FAILURE = "RE_ASSIGN_DRIVER_FAILURE";

export const RE_ASSIGN_CHANGE_DRIVER = "RE_ASSIGN_CHANGE_DRIVER";
export const RE_ASSIGN_CHANGE_DRIVER_SUCCESS =
  "RE_ASSIGN_CHANGE_DRIVER_SUCCESS";
export const RE_ASSIGN_CHANGE_DRIVER_FAILURE =
  "RE_ASSIGN_CHANGE_DRIVER_FAILURE";

export const CHANGE_DRIVER = "CHANGE_DRIVER";
export const CHANGE_DRIVER_SUCCESS = "CHANGE_DRIVER_SUCCESS";
export const CHANGE_DRIVER_FAILURE = "CHANGE_DRIVER_FAILURE";

export const FETCH_DRIVERS_JOBS = "FETCH_DRIVERS_JOBS";
export const FETCH_DRIVERS_JOBS_SUCCESS = "FETCH_DRIVERS_JOBS_SUCCESS";
export const FETCH_DRIVERS_JOBS_FAILURE = "FETCH_DRIVERS_JOBS_FAILURE";

export const FETCH_DRIVERS_RUNSHEET = "FETCH_DRIVERS_RUNSHEET";
export const FETCH_DRIVERS_RUNSHEET_SUCCESS = "FETCH_DRIVERS_RUNSHEET_SUCCESS";
export const FETCH_DRIVERS_RUNSHEET_FAILURE = "FETCH_DRIVERS_RUNSHEET_FAILURE";

export const FETCH_DRIVER_TIMESHEET = "FETCH_DRIVER_TIMESHEET";
export const FETCH_DRIVER_TIMESHEET_SUCCESS = "FETCH_DRIVER_TIMESHEET_SUCCESS";
export const FETCH_DRIVER_TIMESHEET_FAILURE = "FETCH_DRIVER_TIMESHEET_FAILURE";

export const FETCH_NOT_APPROVED_DRIVER_TIMESHEET =
  "FETCH_NOT_APPROVED_DRIVER_TIMESHEET";
export const FETCH_NOT_APPROVED_DRIVER_TIMESHEET_SUCCESS =
  "FETCH_NOT_APPROVED_DRIVER_TIMESHEET_SUCCESS";
export const FETCH_NOT_APPROVED_DRIVER_TIMESHEET_FAILURE =
  "FETCH_NOT_APPROVED_DRIVER_TIMESHEET_FAILURE";

export const APPROVE_DRIVER_TIMESHEET = "APPROVE_DRIVER_TIMESHEET";
export const APPROVE_DRIVER_TIMESHEET_SUCCESS =
  "APPROVE_DRIVER_TIMESHEET_SUCCESS";
export const APPROVE_DRIVER_TIMESHEET_FAILURE =
  "APPROVE_DRIVER_TIMESHEET_FAILURE";

export const FETCH_TIMESHEET_LIST = "FETCH_TIMESHEET_LIST";
export const FETCH_TIMESHEET_LIST_SUCCESS = "FETCH_TIMESHEET_LIST_SUCCESS";
export const FETCH_TIMESHEET_LIST_FAILURE = "FETCH_TIMESHEET_LIST_FAILURE";

export const UPDATE_DRIVER_TIMESHEET = "UPDATE_DRIVER_TIMESHEET";
export const UPDATE_DRIVER_TIMESHEET_SUCCESS =
  "UPDATE_DRIVER_TIMESHEET_SUCCESS";
export const UPDATE_DRIVER_TIMESHEET_FAILURE =
  "UPDATE_DRIVER_TIMESHEET_FAILURE";

export const DOWNLOAD_DRIVER_TIMESHEET = "DOWNLOAD_DRIVER_TIMESHEET";
export const DOWNLOAD_DRIVER_TIMESHEET_SUCCESS =
  "DOWNLOAD_DRIVER_TIMESHEET_SUCCESS";
export const DOWNLOAD_DRIVER_TIMESHEET_FAILURE =
  "DOWNLOAD_DRIVER_TIMESHEET_FAILURE";

export const CREATE_COMPLETED_JOB = "CREATE_COMPLETED_JOB";
export const CREATE_COMPLETED_JOB_SUCCESS = "CREATE_COMPLETED_JOB_SUCCESS";
export const CREATE_COMPLETED_JOB_FAILURE = "CREATE_COMPLETED_JOB_FAILURE";

export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const CREATE_JOB = "CREATE_JOB";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAILURE = "CREATE_JOB_FAILURE";

export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const FETCH_JOBS_ID = "FETCH_JOBS_ID";
export const FETCH_JOBS_ID_SUCCESS = "FETCH_JOBS_ID_SUCCESS";
export const FETCH_JOBS_ID_FAILURE = "FETCH_JOBS_ID_FAILURE";

export const EDIT_JOB = "EDIT_JOB";
export const EDIT_JOB_SUCCESS = "EDIT_JOB_SUCCESS";
export const EDIT_JOB_FAILURE = "EDIT_JOB_FAILURE";

export const REVIEW_JOBS = "REVIEW_JOBS";
export const REVIEW_JOBS_SUCCESS = "REVIEW_JOBS_SUCCESS";
export const REVIEW_JOBS_FAILURE = "REVIEW_JOBS_FAILURE";

export const FETCH_OFFLINE_JOBS = "FETCH_OFFLINE_JOBS";
export const FETCH_OFFLINE_JOBS_SUCCESS = "FETCH_OFFLINE_JOBS_SUCCESS";
export const FETCH_OFFLINE_JOBS_FAILURE = "FETCH_OFFLINE_JOBS_FAILURE";

export const DELETE_OFFLINE_JOBS = "DELETE_OFFLINE_JOBS";
export const DELETE_OFFLINE_JOBS_SUCCESS = "DELETE_OFFLINE_JOBS_SUCCESS";
export const DELETE_OFFLINE_JOBS_FAILURE = "DELETE_OFFLINE_JOBS_FAILURE";

export const CREATE_COMPLETED_OFFLINE_JOB = "CREATE_COMPLETED_OFFLINE_JOB";
export const CREATE_COMPLETED_OFFLINE_JOB_SUCCESS =
  "CREATE_COMPLETED_OFFLINE_JOB_SUCCESS";
export const CREATE_COMPLETED_OFFLINE_JOB_FAILURE =
  "CREATE_COMPLETED_OFFLINE_JOB_FAILURE";

export const GET_JOB_RUNSHEET = "GET_JOB_RUNSHEET";
export const GET_JOB_RUNSHEET_SUCCESS = "GET_JOB_RUNSHEET_SUCCESS";
export const GET_JOB_RUNSHEET_FAILURE = "GET_JOB_RUNSHEET_FAILURE";

export const UPDATE_JOB_RUNSHEET = "UPDATE_JOB_RUNSHEET";
export const UPDATE_JOB_RUNSHEET_SUCCESS = "UPDATE_JOB_RUNSHEET_SUCCESS";
export const UPDATE_JOB_RUNSHEET_FAILURE = "UPDATE_JOB_RUNSHEET_FAILURE";

export const DOWNLOAD_PDF_JOB_RUNSHEET = "DOWNLOAD_PDF_JOB_RUNSHEET";
export const DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS =
  "DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS";
export const DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE =
  "DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE";

export const DOWNLOAD_CSV_JOB_RUNSHEET = "DOWNLOAD_CSV_JOB_RUNSHEET";
export const DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS =
  "DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS";
export const DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE =
  "DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE";

export const FETCH_JOB_CONSIGNMENT = "FETCH_JOB_CONSIGNMENT";
export const FETCH_JOB_CONSIGNMENT_SUCCESS = "FETCH_JOB_CONSIGNMENT_SUCCESS";
export const FETCH_JOB_CONSIGNMENT_FAILURE = "FETCH_JOB_CONSIGNMENT_FAILURE";

export const ADD_JOB_CONSIGNMENT = "ADD_JOB_CONSIGNMENT";
export const ADD_JOB_CONSIGNMENT_SUCCESS = "ADD_JOB_CONSIGNMENT_SUCCESS";
export const ADD_JOB_CONSIGNMENT_FAILURE = "ADD_JOB_CONSIGNMENT_FAILURE";

export const EDIT_JOB_CONSIGNMENT = "EDIT_JOB_CONSIGNMENT";
export const EDIT_JOB_CONSIGNMENT_SUCCESS = "EDIT_JOB_CONSIGNMENT_SUCCESS";
export const EDIT_JOB_CONSIGNMENT_FAILURE = "EDIT_JOB_CONSIGNMENT_FAILURE";

export const DELETE_JOB_CONSIGNMENT = "DELETE_JOB_CONSIGNMENT";
export const DELETE_JOB_CONSIGNMENT_SUCCESS = "DELETE_JOB_CONSIGNMENT_SUCCESS";
export const DELETE_JOB_CONSIGNMENT_FAILURE = "DELETE_JOB_CONSIGNMENT_FAILURE";

export const UPLOAD_IMAGE_ADD_CONSIGNMENT = "UPLOAD_IMAGE_ADD_CONSIGNMENT";
export const UPLOAD_IMAGE_ADD_CONSIGNMENT_SUCCESS =
  "UPLOAD_IMAGE_ADD_CONSIGNMENT_SUCCESS";
export const UPLOAD_IMAGE_ADD_CONSIGNMENT_FAILURE =
  "UPLOAD_IMAGE_ADD_CONSIGNMENT_FAILURE";

export const FETCH_CONSIGNMENT_ITEM = "FETCH_CONSIGNMENT_ITEM";
export const FETCH_CONSIGNMENT_ITEM_SUCCESS = "FETCH_CONSIGNMENT_ITEM_SUCCESS";
export const FETCH_CONSIGNMENT_ITEM_FAILURE = "FETCH_CONSIGNMENT_ITEM_FAILURE";

export const ADD_CONSIGNMENT_ITEM = "ADD_CONSIGNMENT_ITEM";
export const ADD_CONSIGNMENT_ITEM_SUCCESS = "ADD_CONSIGNMENT_ITEM_SUCCESS";
export const ADD_CONSIGNMENT_ITEM_FAILURE = "ADD_CONSIGNMENT_ITEM_FAILURE";

export const EDIT_CONSIGNMENT_ITEM = "EDIT_CONSIGNMENT_ITEM";
export const EDIT_CONSIGNMENT_ITEM_SUCCESS = "EDIT_CONSIGNMENT_ITEM_SUCCESS";
export const EDIT_CONSIGNMENT_ITEM_FAILURE = "EDIT_CONSIGNMENT_ITEM_FAILURE";

export const DELETE_CONSIGNMENT_ITEM = "DELETE_CONSIGNMENT_ITEM";
export const DELETE_CONSIGNMENT_ITEM_SUCCESS =
  "DELETE_CONSIGNMENT_ITEM_SUCCESS";
export const DELETE_CONSIGNMENT_ITEM_FAILURE =
  "DELETE_CONSIGNMENT_ITEM_FAILURE";

export const IMAGE_UPLOAD_CONSIGNMENT_ITEM = "IMAGE_UPLOAD_CONSIGNMENT_ITEM";
export const IMAGE_UPLOAD_CONSIGNMENT_ITEM_SUCCESS =
  "IMAGE_UPLOAD_CONSIGNMENT_ITEM_SUCCESS";
export const IMAGE_UPLOAD_CONSIGNMENT_ITEM_FAILURE =
  "IMAGE_UPLOAD_CONSIGNMENT_ITEM_FAILURE";

export const FETCH_AWB_LOOSE = "FETCH_AWB_LOOSE";
export const FETCH_AWB_LOOSE_SUCCESS = "FETCH_AWB_LOOSE_SUCCESS";
export const FETCH_AWB_LOOSE_FAILURE = "FETCH_AWB_LOOSE_FAILURE";

export const ADD_AWB_LOOSE = "ADD_AWB_LOOSE";
export const ADD_AWB_LOOSE_SUCCESS = "ADD_AWB_LOOSE_SUCCESS";
export const ADD_AWB_LOOSE_FAILURE = "ADD_AWB_LOOSE_FAILURE";

export const EDIT_AWB_LOOSE = "EDIT_AWB_LOOSE";
export const EDIT_AWB_LOOSE_SUCCESS = "EDIT_AWB_LOOSE_SUCCESS";
export const EDIT_AWB_LOOSE_FAILURE = "EDIT_AWB_LOOSE_FAILURE";

export const DELETE_AWB_LOOSE = "DELETE_AWB_LOOSE";
export const DELETE_AWB_LOOSE_SUCCESS = "DELETE_AWB_LOOSE_SUCCESS";
export const DELETE_AWB_LOOSE_FAILURE = "DELETE_AWB_LOOSE_FAILURE";

export const FETCH_AWB_ULD = "FETCH_AWB_ULD";
export const FETCH_AWB_ULD_SUCCESS = "FETCH_AWB_ULD_SUCCESS";
export const FETCH_AWB_ULD_FAILURE = "FETCH_AWB_ULD_FAILURE";

export const ADD_AWB_ULD = "ADD_AWB_ULD";
export const ADD_AWB_ULD_SUCCESS = "ADD_AWB_ULD_SUCCESS";
export const ADD_AWB_ULD_FAILURE = "ADD_AWB_ULD_FAILURE";

export const EDIT_AWB_ULD = "EDIT_AWB_ULD";
export const EDIT_AWB_ULD_SUCCESS = "EDIT_AWB_ULD_SUCCESS";
export const EDIT_AWB_ULD_FAILURE = "EDIT_AWB_ULD_FAILURE";

export const DELETE_AWB_ULD = "DELETE_AWB_ULD";
export const DELETE_AWB_ULD_SUCCESS = "DELETE_AWB_ULD_SUCCESS";
export const DELETE_AWB_ULD_FAILURE = "DELETE_AWB_ULD_FAILURE";

export const FETCH_JOB_PRICE_MATRIX = "FETCH_JOB_PRICE_MATRIX";
export const FETCH_JOB_PRICE_MATRIX_SUCCESS = "FETCH_JOB_PRICE_MATRIX_SUCCESS";
export const FETCH_JOB_PRICE_MATRIX_FAILURE = "FETCH_JOB_PRICE_MATRIX_FAILURE";

export const UPDATE_JOB_PRICE_MATRIX = "UPDATE_JOB_PRICE_MATRIX";
export const UPDATE_JOB_PRICE_MATRIX_SUCCESS =
  "UPDATE_JOB_PRICE_MATRIX_SUCCESS";
export const UPDATE_JOB_PRICE_MATRIX_FAILURE =
  "UPDATE_JOB_PRICE_MATRIX_FAILURE";

export const FETCH_ADDITIONAL_CHARGES = "FETCH_ADDITIONAL_CHARGES";
export const FETCH_ADDITIONAL_CHARGES_SUCCESS =
  "FETCH_ADDITIONAL_CHARGES_SUCCESS";
export const FETCH_ADDITIONAL_CHARGES_FAILURE =
  "FETCH_ADDITIONAL_CHARGES_FAILURE";

export const UPDATE_ADDITIONAL_CHARGES = "UPDATE_ADDITIONAL_CHARGES";
export const UPDATE_ADDITIONAL_CHARGES_SUCCESS =
  "UPDATE_ADDITIONAL_CHARGES_SUCCESS";
export const UPDATE_ADDITIONAL_CHARGES_FAILURE =
  "UPDATE_ADDITIONAL_CHARGES_FAILURE";

export const UPDATE_JOB_DETAILS = "UPDATE_JOB_DETAILS";
export const UPDATE_JOB_DETAILS_SUCCESS = "UPDATE_JOB_DETAILS_SUCCESS";
export const UPDATE_JOB_DETAILS_FAILURE = "UPDATE_JOB_DETAILS_FAILURE";

export const UPDATE_JOB_DURATION = "UPDATE_JOB_DURATION";
export const UPDATE_JOB_DURATION_SUCCESS = "UPDATE_JOB_DURATION_SUCCESS";
export const UPDATE_JOB_DURATION_FAILURE = "UPDATE_JOB_DURATION_FAILURE";

export const FETCH_JOB_TOLL_CHARGE = "FETCH_JOB_TOLL_CHARGE";
export const FETCH_JOB_TOLL_CHARGE_SUCCESS = "FETCH_JOB_TOLL_CHARGE_SUCCESS";
export const FETCH_JOB_TOLL_CHARGE_FAILURE = "FETCH_JOB_TOLL_CHARGE_FAILURE";

export const DELETE_JOB_TOLL_CHARGE = "DELETE_JOB_TOLL_CHARGE";
export const DELETE_JOB_TOLL_CHARGE_SUCCESS = "DELETE_JOB_TOLL_CHARGE_SUCCESS";
export const DELETE_JOB_TOLL_CHARGE_FAILURE = "DELETE_JOB_TOLL_CHARGE_FAILURE";

export const FETCH_STAFF_USERS = "FETCH_STAFF_USERS";
export const FETCH_STAFF_USERS_SUCCESS = "FETCH_STAFF_USERS_SUCCESS";
export const FETCH_STAFF_USERS_FAILURE = "FETCH_STAFF_USERS_FAILURE";

export const ADD_STAFF_USER = "ADD_STAFF_USER";
export const ADD_STAFF_USER_SUCCESS = "ADD_STAFF_USER_SUCCESS";
export const ADD_STAFF_USER_FAILURE = "ADD_STAFF_USER_FAILURE";

export const EDIT_STAFF_USER = "EDIT_STAFF_USER";
export const EDIT_STAFF_USER_SUCCESS = "EDIT_STAFF_USER_SUCCESS";
export const EDIT_STAFF_USER_FAILURE = "EDIT_STAFF_USER_FAILURE";

export const DELETE_STAFF_USER = "DELETE_STAFF_USER";
export const DELETE_STAFF_USER_SUCCESS = "DELETE_STAFF_USER_SUCCESS";
export const DELETE_STAFF_USER_FAILURE = "DELETE_STAFF_USER_FAILURE";

export const FETCH_TOLL_LOCATION = "FETCH_TOLL_LOCATION";
export const FETCH_TOLL_LOCATION_SUCCESS = "FETCH_TOLL_LOCATION_SUCCESS";
export const FETCH_TOLL_LOCATION_FAILURE = "FETCH_TOLL_LOCATION_FAILURE";

export const DELETE_TOLL_LOCATION = "DELETE_TOLL_LOCATION";
export const DELETE_TOLL_LOCATION_SUCCESS = "DELETE_TOLL_LOCATION_SUCCESS";
export const DELETE_TOLL_LOCATION_FAILURE = "DELETE_TOLL_LOCATION_FAILURE";

export const ADD_TOLL_LOCATION = "ADD_TOLL_LOCATION";
export const ADD_TOLL_LOCATION_SUCCESS = "ADD_TOLL_LOCATION_SUCCESS";
export const ADD_TOLL_LOCATION_FAILURE = "ADD_TOLL_LOCATION_FAILURE";

export const EDIT_TOLL_LOCATION = "EDIT_TOLL_LOCATION";
export const EDIT_TOLL_LOCATION_SUCCESS = "EDIT_TOLL_LOCATION_SUCCESS";
export const EDIT_TOLL_LOCATION_FAILURE = "EDIT_TOLL_LOCATION_FAILURE";

export const FETCH_CTO = "FETCH_CTO";
export const FETCH_CTO_SUCCESS = "FETCH_CTO_SUCCESS";
export const FETCH_CTO_FAILURE = "FETCH_CTO_FAILURE";

export const ADD_CTO = "ADD_CTO";
export const ADD_CTO_SUCCESS = "ADD_CTO_SUCCESS";
export const ADD_CTO_FAILURE = "ADD_CTO_FAILURE";

export const DELETE_CTO = "DELETE_CTO";
export const DELETE_CTO_SUCCESS = "DELETE_CTO_SUCESS";
export const DELETE_CTO_FAILURE = "DELETE_CTO_FAILURE";

export const EDIT_CTO = "EDIT_CTO";
export const EDIT_CTO_SUCCESS = "EDIT_CTO_SUCESS";
export const EDIT_CTO_FAILURE = "EDIT_CTO_FAILURE";

export const FETCH_TRUCKS = "FETCH_TRUCKS";
export const FETCH_TRUCKS_SUCCESS = "FETCH_TRUCKS_SUCCESS";
export const FETCH_TRUCKS_FAILURE = "FETCH_TRUCKS_FAILURE";

export const ADD_TRUCK = "ADD_TRUCK";
export const ADD_TRUCK_SUCCESS = "ADD_TRUCK_SUCCESS";
export const ADD_TRUCK_FAILURE = "ADD_TRUCK_FAILURE";

export const EDIT_TRUCK = "EDIT_TRUCK";
export const EDIT_TRUCK_SUCCESS = "EDIT_TRUCK_SUCCESS";
export const EDIT_TRUCK_FAILURE = "EDIT_TRUCK_FAILURE";

export const DELETE_TRUCK = "DELETE_TRUCK";
export const DELETE_TRUCK_SUCCESS = "DELETE_TRUCK_SUCCESS";
export const DELETE_TRUCK_FAILURE = "DELETE_TRUCK_FAILURE";

export const IMPORT_TRUCK_CSV = "IMPORT_TRUCK_CSV";
export const IMPORT_TRUCK_CSV_SUCCESS = "IMPORT_TRUCK_CSV_SUCCESS";
export const IMPORT_TRUCK_CSV_FAILURE = "IMPORT_TRUCK_CSV_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const ADD_AWB = "ADD_AWB";
export const ADD_AWB_SUCCESS = "ADD_AWB_SUCCESS";
export const ADD_AWB_FAILURE = " ADD_AWB_FAILURE";

export const EDIT_AWB = "EDIT_AWB";
export const EDIT_AWB_SUCCESS = "EDIT_AWB_SUCCESS";
export const EDIT_AWB_FAILURE = "EDIT_AWB_FAILURE";

export const DELETE_AWB = "DELETE_AWB";
export const DELETE_AWB_SUCCESS = "DELETE_AWB_SUCCESS";
export const DELETE_AWB_FAILURE = "DELETE_AWB_FAILURE";

export const ISSUE_INVOICE = "ISSUE_INVOICE";

export const FETCH_TRAILER = "FETCH_TRAILER";
export const FETCH_TRAILER_SUCCESS = "FETCH_TRAILER_SUCCESS";
export const FETCH_TRAILER_FAILURE = "FETCH_TRAILER_FAILURE";

export const ADD_TRAILER = "ADD_TRAILER";
export const ADD_TRAILER_SUCCESS = "ADD_TRAILER_SUCCESS";
export const ADD_TRAILER_FAILURE = "ADD_TRAILER_FAILURE";

export const EDIT_TRAILER = "EDIT_TRAILER";
export const EDIT_TRAILER_SUCCESS = "EDIT_TRAILER_SUCCESS";
export const EDIT_TRAILER_FAILURE = "EDIT_TRAILER_FAILURE";

export const DELETE_TRAILER = "DELETE_TRAILER";
export const DELETE_TRAILER_SUCCESS = "DELETE_TRAILER_SUCCESS";
export const DELETE_TRAILER_FAILURE = "DELETE_TRAILER_FAILURE";

export const IMPORT_TRAILER_CSV = "IMPORT_TRAILER_CSV";
export const IMPORT_TRAILER_CSV_SUCCESS = "IMPORT_TRAILER_CSV_SUCCESS";
export const IMPORT_TRAILER_CSV_FAILURE = "IMPORT_TRAILER_CSV_FAILURE";

export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const FETCH_HOLIDAYS_SUCCESS = "FETCH_HOLIDAYS_SUCCESS";
export const FETCH_HOLIDAYS_FAILURE = "FETCH_HOLIDAYS_FAILURE";

export const ADD_HOLIDAYS = "ADD_HOLIDAYS";
export const ADD_HOLIDAYS_SUCCESS = "ADD_HOLIDAYS_SUCCESS";
export const ADD_HOLIDAYS_FAILURE = "ADD_HOLIDAYS_FAILURE";

export const EDIT_HOLIDAYS = "EDIT_HOLIDAYS";
export const EDIT_HOLIDAYS_SUCCESS = "EDIT_HOLIDAYS_SUCCESS";
export const EDIT_HOLIDAYS_FAILURE = "EDIT_HOLIDAYS_FAILURE";

export const DELETE_HOLIDAYS = "DELETE_HOLIDAYS";
export const DELETE_HOLIDAYS_SUCCESS = "DELETE_HOLIDAYS_SUCCESS";
export const DELETE_HOLIDAYS_FAILURE = "DELETE_HOLIDAYS_FAILURE";

export const FETCH_AIR_WAY_BILLS = "FETCH_AIR_WAY_BILLS";
export const FETCH_AIR_WAY_BILLS_SUCCESS = "FETCH_AIR_WAY_BILLS_SUCCESS";
export const FETCH_AIR_WAY_BILLS_FAILURE = "FETCH_AIR_WAY_BILLS_FAILURE";

export const FETCH_REMINDER = "FETCH_REMINDER";
export const FETCH_REMINDER_SUCCESS = "FETCH_REMINDER_SUCCESS";
export const FETCH_REMINDER_FAILURE = "FETCH_REMINDER_FAILURE";

export const FETCH_ONLINE_REQUEST = "FETCH_ONLINE_REQUEST";
export const FETCH_ONLINE_REQUEST_SUCCESS = "FETCH_ONLINE_REQUEST_SUCCESS";
export const FETCH_ONLINE_REQUEST_FAILURE = "FETCH_ONLINE_REQUEST_FAILURE";

export const ACKNOWLEDGE_REQUEST = "ACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_REQUEST_SUCCESS = "ACKNOWLEDGE_REQUEST_SUCCESS";
export const ACKNOWLEDGE_REQUEST_FAILURE = "ACKNOWLEDGE_REQUEST_FAILURE";

export const GET_ONLINE_REQUEST_BY_ID = "GET_ONLINE_REQUEST_BY_ID";
export const GET_ONLINE_REQUEST_BY_ID_SUCCESS =
  "GET_ONLINE_REQUEST_BY_ID_SUCCESS";
export const GET_ONLINE_REQUEST_BY_ID_FAILURE =
  "GET_ONLINE_REQUEST_BY_ID_FAILURE";

export const GET_MAPCOCKPIT = "GET_MAPCOCKPIT";
export const GET_MAPCOCKPIT_SUCCESS = "GET_MAPCOCKPIT_SUCCESS";
export const GET_MAPCOCKPIT_FAILURE = "GET_MAPCOCKPIT_FAILURE";

export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";

export const DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS";
export const DOWNLOAD_REPORTS_SUCCESS = "DOWNLOAD_REPORTS_SUCCESS";
export const DOWNLOAD_REPORTS_FAILURE = "DOWNLOAD_REPORTS_FAILURE";

export const FETCH_DRAFT_LISTING = "FETCH_DRAFT_LISTING";
export const FETCH_DRAFT_LISTING_SUCCESS = "FETCH_DRAFT_LISTING_SUCCESS";
export const FETCH_DRAFT_LISTING_FAILURE = "FETCH_DRAFT_LISTING_FAILURE";

export const UPDATE_DRAFT_INVOICE = "UPDATE_DRAFT_INVOICE";
export const UPDATE_DRAFT_INVOICE_SUCCESS = "UPDATE_DRAFT_INVOICE_SUCCESS";
export const UPDATE_DRAFT_INVOICE_FAILURE = "UPDATE_DRAFT_INVOICE_FAILURE";

export const APPROVE_INVOICE = "APPROVE_INVOICE";
export const APPROVE_INVOICE_SUCCESS = "APPROVE_INVOICE_SUCCESS";
export const APPROVE_INVOICE_FAILURE = "APPROVE_INVOICE_FAILURE";

export const REJECT_INVOICE = "REJECT_INVOICE";
export const REJECT_INVOICE_SUCCESS = "REJECT_INVOICE_SUCCESS";
export const REJECT_INVOICE_FAILURE = "REJECT_INVOICE_FAILURE";

export const FETCH_APPROVED_INVOICES = "FETCH_APPROVED_INVOICES";
export const FETCH_APPROVED_INVOICES_SUCCESS =
  "FETCH_APPROVED_INVOICES_SUCCESS";
export const FETCH_APPROVED_INVOICES_FAILURE =
  "FETCH_APPROVED_INVOICES_FAILURE";

export const DOWNLOAD_APPROVED_INVOICES = "DOWNLOAD_APPROVED_INVOICES";
export const DOWNLOAD_APPROVED_INVOICES_SUCCESS =
  "DOWNLOAD_APPROVED_INVOICES_SUCCESS";
export const DOWNLOAD_APPROVED_INVOICES_FAILURE =
  "DOWNLOAD_APPROVED_INVOICES_FAILURE";

export const DOWNLOAD_APPROVED_INVOICES_PDF = "DOWNLOAD_APPROVED_INVOICES_PDF";
export const DOWNLOAD_APPROVED_INVOICES_PDF_SUCCESS =
  "DOWNLOAD_APPROVED_INVOICES_PDF_SUCCESS";
export const DOWNLOAD_APPROVED_INVOICES_PDF_FAILURE =
  "DOWNLOAD_APPROVED_INVOICES_PDF_FAILURE";

export const DOWNLOAD_APPROVED_INVOICES_CSV = "DOWNLOAD_APPROVED_INVOICES_CSV";
export const DOWNLOAD_APPROVED_INVOICES_CSV_SUCCESS =
  "DOWNLOAD_APPROVED_INVOICES_CSV_SUCCESS";
export const DOWNLOAD_APPROVED_INVOICES_CSV_FAILURE =
  "DOWNLOAD_APPROVED_INVOICES_CSV_FAILURE";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const FETCH_REJECTED_INVOICES = "FETCH_REJECTED_INVOICES";
export const FETCH_REJECTED_INVOICES_SUCCESS =
  "FETCH_REJECTED_INVOICES_SUCCESS";
export const FETCH_REJECTED_INVOICES_FAILURE =
  "FETCH_REJECTED_INVOICES_FAILURE";

export const RESOLVE_REJECTED_INVOICES = "RESOLVE_REJECTED_INVOICES";
export const RESOLVE_REJECTED_INVOICES_SUCCESS =
  "RESOLVE_REJECTED_INVOICES_SUCCESS";
export const RESOLVE_REJECTED_INVOICES_FAILURE =
  "RESOLVE_REJECTED_INVOICES_FAILURE";

export const FETCH_MIV_INVOICES = "FETCH_MIV_INVOICES";
export const FETCH_MIV_INVOICES_SUCCESS = "FETCH_MIV_INVOICES_SUCCESS";
export const FETCH_MIV_INVOICES_FAILURE = "FETCH_MIV_INVOICES_FAILURE";

export const ASSIGN_MIV = "ASSIGN_MIV";
export const ASSIGN_MIV_SUCCESS = "ASSIGN_MIV_SUCCESS";
export const ASSIGN_MIV_FAILURE = "ASSIGN_MIV_FAILURE";

export const DONE_MIV = "DONE_MIV";
export const DONE_MIV_SUCCESS = "DONE_MIV_SUCCESS";
export const DONE_MIV_FAILURE = "DONE_MIV_FAILURE";

export const FETCH_INVOICE_DETAIL = "FETCH_INVOICE_DETAIL";
export const FETCH_INVOICE_DETAIL_SUCCESS = "FETCH_INVOICE_DETAIL_SUCCESS";
export const FETCH_INVOICE_DETAIL_FAILURE = "FETCH_INVOICE_DETAIL_FAILURE";

export const MANAGEMENT_DASHBOARD_DATA = "MANAGEMENT_DASHBOARD_DATA";
export const MANAGEMENT_DASHBOARD_DATA_SUCCESS =
  "MANAGEMENT_DASHBOARD_DATA_SUCCESS";
export const MANAGEMENT_DASHBOARD_DATA_FAILURE =
  "MANAGEMENT_DASHBOARD_DATA_FAILURE";

export const DASHBOARD_JOB_DRIVER_MANAGE = "DASHBOARD_JOB_DRIVER_MANAGE";
export const DASHBOARD_JOB_DRIVER_MANAGE_SUCCESS =
  "DASHBOARD_JOB_DRIVER_MANAGE_SUCCESS";
export const DASHBOARD_JOB_DRIVER_MANAGE_FAILURE =
  "DASHBOARD_JOB_DRIVER_MANAGE_FAILURE";

export const DASHBOARD_AVERAGE = "DASHBOARD_AVERAGE";
export const DASHBOARD_AVERAGE_SUCCESS = "DASHBOARD_AVERAGE_SUCCESS";
export const DASHBOARD_AVERAGE_FAILURE = "DASHBOARD_AVERAGE_FAILURE";

export const DASHBOARD_STATUS_TIMEOVER = "DASHBOARD_STATUS_TIMEOVER";
export const DASHBOARD_STATUS_TIMEOVER_SUCCESS =
  "DASHBOARD_STATUS_TIMEOVER_SUCCESS";
export const DASHBOARD_STATUS_TIMEOVER_FAILURE =
  "DASHBOARD_STATUS_TIMEOVER_FAILURE";

export const GET_DRAFT_INVOICE_CHAT = "GET_DRAFT_INVOICE_CHAT";
export const GET_DRAFT_INVOICE_CHAT_SUCCESS = "GET_DRAFT_INVOICE_CHAT_SUCCESS";
export const GET_DRAFT_INVOICE_CHAT_FAILURE = "GET_DRAFT_INVOICE_CHAT_FAILURE";

export const SAVE_DRAFT_CHAT = "SAVE_DRAFT_CHAT";
export const SAVE_DRAFT_CHAT_SUCCESS = "SAVE_DRAFT_CHAT_SUCCESS";
export const SAVE_DRAFT_CHAT_FAILURE = "SAVE_DRAFT_CHAT_FAILURE";

export const RESET_REDIRECTION = "RESET_REDIRECTION";

export const CARGO_VOLUME = "CARGO_VOLUME";
export const JOBS_GRAPH_FILTER = "JOBS_GRAPH_FILTER";
export const JOBS_STATUS_REDIRECTION = "JOBS_STATUS_REDIRECTION";

export const DRIVERS_GRAPH_FILTER = "DRIVERS_GRAPH_FILTER";
export const FATIGUE_FILTER = "FATIGUE_FILTER";
export const DASHBOARD_FILTER_REDIRECTION = "DASHBOARD_FILTER_REDIRECTION";

export const INVOICE_GRAPH_FILTER = "INVOICE_GRAPH_FILTER";

export const GET_ONLINE_REQUEST_CHAT = "GET_ONLINE_REQUEST_CHAT";
export const GET_ONLINE_REQUEST_CHAT_SUCCESS =
  "GET_ONLINE_REQUEST_CHAT_SUCCESS";
export const GET_ONLINE_REQUEST_CHAT_FAILURE =
  "GET_ONLINE_REQUEST_CHAT_FAILURE";

export const SAVE_ONLINE_REQUEST_CHAT = "SAVE_ONLINE_REQUEST_CHAT";
export const SAVE_ONLINE_REQUEST_CHAT_SUCCESS =
  "SAVE_ONLINE_REQUEST_CHAT_SUCCESS";
export const SAVE_ONLINE_REQUEST_CHAT_FAILURE =
  "SAVE_ONLINE_REQUEST_CHAT_FAILURE";

export const FETCH_PARENT_ORGANISATION = "FETCH_PARENT_ORGANISATION";
export const FETCH_PARENT_ORGANISATION_SUCCESS =
  "FETCH_PARENT_ORGANISATION_SUCCESS";
export const FETCH_PARENT_ORGANISATION_FAILURE =
  "FETCH_PARENT_ORGANISATION_FAILURE";

export const ADD_PARENT_ORGANISATION = "ADD_PARENT_ORGANISATION";
export const ADD_PARENT_ORGANISATION_SUCCESS =
  "ADD_PARENT_ORGANISATION_SUCCESS";
export const ADD_PARENT_ORGANISATION_FAILURE =
  "ADD_PARENT_ORGANISATION_FAILURE";

export const EDIT_PARENT_ORGANISATION = "EDIT_PARENT_ORGANISATION";
export const EDIT_PARENT_ORGANISATION_SUCCESS =
  "EDIT_PARENT_ORGANISATION_SUCCESS";
export const EDIT_PARENT_ORGANISATION_FAILURE =
  "EDIT_PARENT_ORGANISATION_FAILURE";

export const FETCH_CHILD_ORGANISATION = "FETCH_CHILD_ORGANISATION";
export const FETCH_CHILD_ORGANISATION_SUCCESS =
  "FETCH_CHILD_ORGANISATION_SUCCESS";
export const FETCH_CHILD_ORGANISATION_FAILURE =
  "FETCH_CHILD_ORGANISATION_FAILURE";

export const ADD_CHILD_ORGANISATION = "ADD_CHILD_ORGANISATION";
export const ADD_CHILD_ORGANISATION_SUCCESS = "ADD_CHILD_ORGANISATION_SUCCESS";
export const ADD_CHILD_ORGANISATION_FAILURE = "ADD_CHILD_ORGANISATION_FAILURE";

export const EDIT_CHILD_ORGANISATION = "EDIT_CHILD_ORGANISATION";
export const EDIT_CHILD_ORGANISATION_SUCCESS =
  "EDIT_CHILD_ORGANISATION_SUCCESS";
export const EDIT_CHILD_ORGANISATION_FAILURE =
  "EDIT_CHILD_ORGANISATION_FAILURE";

export const FETCH_CUSTOMER_MANUAL_INVOICES = "FETCH_CUSTOMER_MANUAL_INVOICES";
export const FETCH_CUSTOMER_MANUAL_INVOICES_SUCCESS =
  "FETCH_CUSTOMER_MANUAL_INVOICES_SUCCESS";
export const FETCH_CUSTOMER_MANUAL_INVOICES_FAILURE =
  "FETCH_CUSTOMER_MANUAL_INVOICES_FAILURE";

export const GENERATE_MANUAL_INVOICE = "GENERATE_MANUAL_INVOICE";
export const GENERATE_MANUAL_INVOICE_SUCCESS =
  "GENERATE_MANUAL_INVOICE_SUCCESS";
export const GENERATE_MANUAL_INVOICE_FAILURE =
  "GENERATE_MANUAL_INVOICE_FAILURE";

export const FETCH_MANUAL_DRAFT_LISTING = "FETCH_MANUAL_DRAFT_LISTING";
export const FETCH_MANUAL_DRAFT_LISTING_SUCCESS =
  "FETCH_MANUAL_DRAFT_LISTING_SUCCESS";
export const FETCH_MANUAL_DRAFT_LISTING_FAILURE =
  "FETCH_MANUAL_DRAFT_LISTING_FAILURE";

export const UPDATE_MANUAL_DRAFT_INVOICE = "UPDATE_MANUAL_DRAFT_INVOICE";
export const UPDATE_MANUAL_DRAFT_INVOICE_SUCCESS =
  "UPDATE_MANUAL_DRAFT_INVOICE_SUCCESS";
export const UPDATE_MANUAL_DRAFT_INVOICE_FAILURE =
  "UPDATE_MANUAL_DRAFT_INVOICE_FAILURE";

export const REJECT_MANUAL_INVOICE = "REJECT_MANUAL_INVOICE";
export const REJECT_MANUAL_INVOICE_SUCCESS = "REJECT_MANUAL_INVOICE_SUCCESS";
export const REJECT_MANUAL_INVOICE_FAILURE = "REJECT_MANUAL_INVOICE_FAILURE";

export const APPROVE_MANUAL_INVOICE = "APPROVE_MANUAL_INVOICE";
export const APPROVE_MANUAL_INVOICE_SUCCESS = "APPROVE_MANUAL_INVOICE_SUCCESS";
export const APPROVE_MANUAL_INVOICE_FAILURE = "APPROVE_MANUAL_INVOICE_FAILURE";
export const FETCH_WAITING_TIME = "FETCH_WAITING_TIME";
export const FETCH_WAITING_TIME_SUCCESS = "FETCH_WAITING_TIME_SUCCESS";
export const FETCH_WAITING_TIME_FAILURE = "FETCH_WAITING_TIME_FAILURE";

export const UPDATE_WAITING_TIME = "UPDATE_WAITING_TIME";
export const UPDATE_WAITING_TIME_SUCCESS = "UPDATE_WAITING_TIME_SUCCESS";
export const UPDATE_WAITING_TIME_FAILURE = "UPDATE_WAITING_TIME_FAILURE";

export const FETCH_NOTE = "FETCH_NOTE";
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS";
export const FETCH_NOTE_FAILURE = "FETCH_NOTE_FAILURE";

export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILURE = "UPDATE_NOTE_FAILURE";

export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_DOCUMENT_FAILURE = "FETCH_DOCUMENT_FAILURE";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";

export const FETCH_CONNECTED_CUSTOMER_USERS = "FETCH_CONNECTED_CUSTOMER_USERS";
export const FETCH_CONNECTED_CUSTOMER_USERS_SUCCESS =
  "FETCH_CONNECTED_CUSTOMER_USERS_SUCCESS";
export const FETCH_CONNECTED_CUSTOMER_USERS_FAILURE =
  "FETCH_CONNECTED_CUSTOMER_USERS_FAILURE";

export const ADD_CONNECTED_CUSTOMER_USER = "ADD_CONNECTED_CUSTOMER_USER";
export const ADD_CONNECTED_CUSTOMER_USER_SUCCESS =
  "ADD_CONNECTED_CUSTOMER_USER_SUCCESS";
export const ADD_CONNECTED_CUSTOMER_USER_FAILURE =
  "ADD_CONNECTED_CUSTOMER_USER_FAILURE";

export const EDIT_CONNECTED_CUSTOMER_USER = "EDIT_CONNECTED_CUSTOMER_USER";
export const EDIT_CONNECTED_CUSTOMER_USER_SUCCESS =
  "EDIT_CONNECTED_CUSTOMER_USER_SUCCESS";
export const EDIT_CONNECTED_CUSTOMER_USER_FAILURE =
  "EDIT_CONNECTED_CUSTOMER_USER_FAILURE";

export const DELETE_CONNECTED_CUSTOMER_USER = "DELETE_CONNECTED_CUSTOMER_USER";
export const DELETE_CONNECTED_CUSTOMER_USER_SUCCESS =
  "DELETE_CONNECTED_CUSTOMER_USER_SUCCESS";
export const DELETE_CONNECTED_CUSTOMER_USER_FAILURE =
  "DELETE_CONNECTED_CUSTOMER_USER_FAILURE";

export const GET_PARENT_CUSTOMER = "GET_PARENT_CUSTOMER";
export const GET_PARENT_CUSTOMER_SUCCESS = "GET_PARENT_CUSTOMER_SUCCESS";
export const GET_PARENT_CUSTOMER_FAILURE = "GET_PARENT_CUSTOMER_FAILURE";
