import {
  FETCH_CONNECTED_CUSTOMER_USERS,
  FETCH_CONNECTED_CUSTOMER_USERS_SUCCESS,
  FETCH_CONNECTED_CUSTOMER_USERS_FAILURE,
  ADD_CONNECTED_CUSTOMER_USER,
  ADD_CONNECTED_CUSTOMER_USER_SUCCESS,
  ADD_CONNECTED_CUSTOMER_USER_FAILURE,
  EDIT_CONNECTED_CUSTOMER_USER,
  EDIT_CONNECTED_CUSTOMER_USER_SUCCESS,
  EDIT_CONNECTED_CUSTOMER_USER_FAILURE,
  DELETE_CONNECTED_CUSTOMER_USER,
  DELETE_CONNECTED_CUSTOMER_USER_SUCCESS,
  DELETE_CONNECTED_CUSTOMER_USER_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loading: false,
  connectedUsers: null,
  addingUser: false,
  addConnectedUserData: null,
  editingUser: false,
  editUserData: null,
  deletingUser: false,
  deleteUserData: null,
};

const connectedUsersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CONNECTED_CUSTOMER_USERS:
      return { ...state, loading: true };
    case FETCH_CONNECTED_CUSTOMER_USERS_SUCCESS:
      return { ...state, connectedUsers: action.payload, loading: false };
    case FETCH_CONNECTED_CUSTOMER_USERS_FAILURE:
      return { ...state, connectedUsers: action.payload, loading: false };

    case ADD_CONNECTED_CUSTOMER_USER:
      return { ...state, addingUser: true };
    case ADD_CONNECTED_CUSTOMER_USER_SUCCESS:
      return { ...state, addConnectedUserData: action.payload, addingUser: false };
    case ADD_CONNECTED_CUSTOMER_USER_FAILURE:
      return { ...state, addConnectedUserData: action.payload, addingUser: false };

    case EDIT_CONNECTED_CUSTOMER_USER:
      return { ...state, editingUser: true };
    case EDIT_CONNECTED_CUSTOMER_USER_SUCCESS:
      return { ...state, editUserData: action.payload, editingUser: false };
    case EDIT_CONNECTED_CUSTOMER_USER_FAILURE:
      return { ...state, editUserData: action.payload, editingUser: false };

    case DELETE_CONNECTED_CUSTOMER_USER:
      return { ...state, deletingUser: true };
    case DELETE_CONNECTED_CUSTOMER_USER_SUCCESS:
      return { ...state, deleteUserData: action.payload, deletingUser: false };
    case DELETE_CONNECTED_CUSTOMER_USER_FAILURE:
      return { ...state, deleteUserData: action.payload, deletingUser: false };
    default:
      return state;
  }
};
export default connectedUsersReducer;
