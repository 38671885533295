import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  CircularProgress,
  FormHelperText,
  Checkbox,
  MenuItem,
  ListItemText,
  Chip,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useStore } from "@store/store";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";
import closeIcon from "@assets/images/close.svg";
import {
  allowAlphaNumeric,
  allowNumberWithSpaceValidation,
} from "@utils/commonFunctions";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { AddPopupStyle } from "./style";

function Popup(props) {
  const classes = AddPopupStyle();
  const materilClasses = materialCommonStyles();
  const [state] = useStore();
  const [scroll] = useState("body");
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [allMenuData, setAllMenuData] = useState([]);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    state?.common?.parentCustomers &&
      setAllMenuData(state.common.parentCustomers);
  }, [state.common.parentCustomers]);

  useEffect(() => {
    allMenuData &&
      setDisplayedOptions(allMenuData.filter((option) => option.name));
  }, [allMenuData]);

  useEffect(() => {
    focused && setDisplayedOptions(allMenuData);
  }, [focused, allMenuData]);

  const onBlur = () => setFocused(true);
  const onFocus = () => setFocused(true);

  return (
    <>
      <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose} />
        </div>
        <form
          noValidate
          autoComplete="off"
          className={classes.customForm}
          onSubmit={props.formik.handleSubmit}
        >
          {props.error && <Alert severity="error">{props.error}</Alert>}
          <DialogTitle>
            {" "}
            {props.isEdit ? "Edit" : "Add"} Connected Customer
          </DialogTitle>
          <DialogContent>
            <div className="form-row">
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Name</label>
                  <TextField
                    name="name"
                    id="name"
                    placeholder="Name"
                    variant="outlined"
                    type="text"
                    onChange={props.formik.handleChange}
                    onKeyPress={allowAlphaNumeric}
                    value={props.formik.values.name}
                    error={
                      props.formik.touched.name &&
                      Boolean(props.formik.errors.name)
                    }
                    helperText={
                      props.formik.touched.name && props.formik.errors.name
                    }
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Email</label>
                  <TextField
                    placeholder="Email address"
                    variant="outlined"
                    type="email"
                    id="email"
                    name="email"
                    onChange={props.formik.handleChange}
                    value={props.formik.values.email}
                    error={
                      props.formik.touched.email &&
                      Boolean(props.formik.errors.email)
                    }
                    helperText={
                      props.formik.touched.email && props.formik.errors.email
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Password</label>
                  <TextField
                    id="password"
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    autoComplete="off"
                    type="password"
                    onChange={props.formik.handleChange}
                    value={props.formik.values.password}
                    error={
                      props.formik.touched.password &&
                      Boolean(props.formik.errors.password)
                    }
                    helperText={
                      props.formik.touched.password &&
                      props.formik.errors.password
                    }
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Confirm Password</label>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    variant="outlined"
                    autoComplete="off"
                    type="password"
                    onChange={props.formik.handleChange}
                    value={props.formik.values.confirmPassword}
                    error={
                      props.formik.touched.password &&
                      Boolean(props.formik.errors.confirmPassword)
                    }
                    helperText={
                      props.formik.touched.confirmPassword &&
                      props.formik.errors.confirmPassword
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text">Phone</label>
                  <TextField
                    placeholder="Phone Number"
                    variant="outlined"
                    type="text"
                    name="phone"
                    id="phone"
                    onKeyPress={allowNumberWithSpaceValidation}
                    onChange={props.formik.handleChange}
                    value={props.formik.values.phone}
                    error={
                      props.formik.touched.phone &&
                      Boolean(props.formik.errors.phone)
                    }
                    helperText={
                      props.formik.touched.phone && props.formik.errors.phone
                    }
                  />
                  {props.formik.touched.phone && props.formik.errors.phone ? (
                    ""
                  ) : (
                    <FormHelperText>
                      {
                        "0X XXXX XXXX or 04XX XXX XXX or 0X XXX XXXX or 0XX XXXX XXXX"
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="form-group">
                <label className="label-text">Parent Customers</label>
                <FormControl
                  variant="outlined"
                  className={classes.autoComplete}
                >
                  <Autocomplete
                    multiple
                    onBlur={onBlur}
                    onFocus={onFocus}
                    className={materilClasses.autoComplete}
                    id="parentCustomer"
                    limitTags={2}
                    options={displayedOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    value={props.formik.values.parentCustomer.map((id) =>
                      displayedOptions.find((option) => option.id === id)
                    )} // Bind to Formik value
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      const newValueIds = newValue.map((e) => e.id);
                      props.formik.setFieldValue("parentCustomer", newValueIds);
                    }}
                    renderOption={(option, { selected }) => (
                      <ul>
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          className={materilClasses.autoComplete}
                        >
                          <Checkbox
                            checked={selected}
                            icon={<img src={uncheckedIcon} alt="CheckBox" />}
                            checkedIcon={
                              <img src={checkedIcon} alt="CheckBox" />
                            }
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      </ul>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <React.Fragment key={option.id}>
                          {index > 0 && (
                            <span className="comma-separator">, </span>
                          )}
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        </React.Fragment>
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        autoFocus
                        fullWidth
                        variant="outlined"
                        className="label-text"
                        placeholder={
                          props.formik.values.parentCustomer.length > 0
                            ? ""
                            : "Select Parent Customer"
                        }
                      />
                    )}
                  />
                  <FormHelperText className="error-text">
                    {props.formik.touched.parentCustomer &&
                      props.formik.errors.parentCustomer}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="bottom-button-block">
            <Button
              className="primary-btn gray-border-btn"
              color="inherit"
              disableElevation
              underlinenone="true"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              className="orange-btn primary-btn"
              color="inherit"
              disableElevation
              underlinenone="true"
              type="submit"
              disabled={
                state.connectedUser.addingUser ||
                state.connectedUser.editingUser
              }
            >
              {state.connectedUser.addingUser ||
              state.connectedUser.editingUser ? (
                <CircularProgress color="inherit" />
              ) : props.isEdit ? (
                "Edit Connected Customer"
              ) : (
                "Add Connected Customer"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default Popup;
